import RestApiClient from 'lib/rest-api-client';
import { GetAnnouncementResult } from './announcements-slice';
import { AnnouncementFromCms, AnnouncementFromFomigo } from 'models';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_HESTIA_SERVICE_API_URL || '',
  timeout: 10 * 1000,
});

const getAnnouncements = async (krBuildingCode: string): Promise<GetAnnouncementResult> => {
  try {
    const response = await restApiClient.get('/announcements', { params: { krBuildingCode } });
    const announcement = response.data?.contents?.map((content: any) => {
      return {
        videoUrl: content.url,
      };
    });
    return {
      status: 'ok',
      announcement: (announcement || []) as AnnouncementFromCms[],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAnnouncementsFromHades = async (serialNumber: string): Promise<GetAnnouncementResult> => {
  try {
    const hadesResponse = await restApiClient.get(`/devices/${serialNumber}/notices?status=running`, {
      baseURL: process.env.REACT_APP_HADES_SERVICE_API_URL,
    });

    return {
      status: 'ok',
      announcementFromFomigo: (hadesResponse.data.list || []) as AnnouncementFromFomigo[],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

export { getAnnouncements, getAnnouncementsFromHades };
