import { useState } from 'react';

import Slider from 'react-slick';
import { styled, Typography } from '@mui/material';

import { useAppSelector } from 'app/hooks';
import { selectPromotions } from 'features/promotions';
import { InitiatorSkeleton } from 'components/skeletons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledSlider = styled(Slider)`
  @media (max-width: 768px) {
    .slick-track {
      display: flex;
    }
    .slick-slide {
      padding: 0 10px;
      display: flex;
      justify-content: center;
    }
    .slick-dots {
      position: unset;
      display: flex !important;
      justify-content: center;
      margin: 0 auto;
      padding: 20px 0 10px;
      max-width: 320px;
      width: 100%;
      list-style-type: none;
      overflow: hidden;
    }
    .slick-dots li {
      position: relative;
      flex: 1;
      margin: 0;
    }
    li.slick-active button {
      position: absolute;
      right: 0;
      width: 100vw;
    }
    .slick-dots button {
      display: block;
      width: 100%;
      height: 4px;
      padding: 0;
      background-color: #e8ebed;
      text-indent: -9999px;
      border: none;
    }
    .slick-dots li.slick-active button {
      background-color: #9ea4aa;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}
`;

const PromotionListWrap = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  marginTop: '20px',
}));

const GuideText = styled('div')(({ theme }) => ({
  margin: '24px auto 0',
  fontWeight: 400,
  fontSize: '1.25rem',
  lineHeight: '1.25rem',
  maxWidth: 664,
  color: '#1B1D1F',
  marginBottom: '15px',
  alignItems: 'center',
  [theme.breakpoints.down('tablet')]: {
    margin: '24px 24px',
  },
}));

const GuideTextBold = styled('span')(() => ({
  fontWeight: 600,
}));

const PromotionList = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    marginTop: '24px',
    marginBottom: '24px',
    margin: '0 auto',
    width: '100%',
    maxWidth: '664px',
  },
}));

const PromotionImage = styled('img')(({ theme }) => ({
  width: '320px',
  height: '320px',
  border: '1px solid #E8EBED',
  margin: '0 auto',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '288px',
    maxHeight: '288px',
  },
}));

const Promotion = styled('div')(({ theme }) => ({
  maxWidth: '320px',
  maxHeight: '320px',
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '288px',
    maxHeight: '288px',
  },
}));

const TitleWrap = styled('div')(() => ({
  width: '100%',
  background: 'linear-gradient(180deg, rgba(27, 29, 31, 0) 0%, rgba(27, 29, 31, 0.8) 100%)',
  position: 'absolute',
  bottom: 0,
}));

const PromotionTitle = styled('span')(({ theme }) => ({
  display: 'block',
  margin: '28px 20px 24px',
  zIndex: 1,
  fontWeight: 600,
  color: '#fff',
  fontSize: '1.75rem',
  lineHeight: '2rem',
  [theme.breakpoints.down('mobile')]: {
    margin: '28px 16px 24px',
  },
}));

const Pagination = styled('div')(({ theme }) => ({
  position: 'unset',
  display: 'flex',
  justifyContent: 'right',
  listStyleType: 'none',
  maxWidth: '320px',
  margin: '0 auto',
  [theme.breakpoints.up('tablet')]: {
    visibility: 'hidden',
  },
}));

const TotalPage = styled('span')(() => ({
  color: '#9EA4AA',
}));

const Promotions = (): JSX.Element => {
  const { items, loading } = useAppSelector(selectPromotions);
  const [page, setPage] = useState(1);
  const [dragging, setDragging] = useState<boolean>(false);

  const setting = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    centerMode: true,
    speed: 300,
    useTransform: false,
    variableWidth: items.length > 1,
    centerPadding: '0px 0px',
    touchThreshold: 100,
    beforeChange: () => setDragging(true),
    afterChange: (current: number) => {
      setPage(current + 1);
      setDragging(false);
    },
  };

  const landingTo = (regularMember: boolean, landingURL: string): any => {
    if (dragging) {
      return;
    }
    if (regularMember) {
      const form = document.getElementById('fmk') as HTMLFormElement;
      if (form) {
        form.redirect.value = landingURL;
        form.submit();
      }
    } else {
      window.location.href = landingURL;
    }
  };

  return (
    <>
      <PromotionListWrap>
        <Typography>
          <GuideText>
            참여하고 싶은 <GuideTextBold>이벤트</GuideTextBold>를 선택해주세요
          </GuideText>
        </Typography>
        <PromotionList>
          {loading === 'loading' ? (
            <InitiatorSkeleton style={{ height: '300px' }} />
          ) : (
            <>
              <StyledSlider
                {...setting}
                responsive={[
                  {
                    breakpoint: 767,
                    settings: {},
                  },
                  {
                    breakpoint: 10000,
                    settings: 'unslick',
                  },
                ]}
              >
                {items.map((promotion, idx) => (
                  <Promotion onClick={() => landingTo(promotion.regularMember, promotion.landingPageUrl)} key={idx}>
                    <TitleWrap>
                      <Typography>
                        <PromotionTitle>
                          {promotion.text[0]}
                          <br />
                          {promotion.text[1]}
                        </PromotionTitle>
                      </Typography>
                    </TitleWrap>
                    <PromotionImage src={promotion.imageUrl} alt="" />
                  </Promotion>
                ))}
              </StyledSlider>
              <Pagination>
                {page}&nbsp;
                <TotalPage>/ {items.length}</TotalPage>
              </Pagination>
            </>
          )}
        </PromotionList>
      </PromotionListWrap>
    </>
  );
};

export { Promotions };
