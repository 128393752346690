import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetPromotionResult } from './promotions-slice';
import { getPromotions, getPreviewPromotions } from './promotions-api';
import { showError, showWarning } from '../notification/notification-slice';

interface GetOption {
  buildingId?: string;
  code?: string;
  packageNumber: string;
  promotionId?: string;
}

const getAsync = createAsyncThunk(
  'promotions/get',
  async (option: GetOption, { dispatch }): Promise<GetPromotionResult> => {
    try {
      const response = await getPromotions(option);
      if (response.status === 'ok' && response.promotions.length === 0) {
        dispatch(showWarning({ message: '단지에 속한 배너가 없습니다' }));
      }
      return response;
    } catch (err) {
      dispatch(showError({ message: '배너 조회 중 오류가 발생했습니다' }));
      return Promise.reject(err);
    }
  },
);

const getPreviewAsync = createAsyncThunk(
  'promotions/preview/get',
  async (promotionId: string, { dispatch }): Promise<GetPromotionResult> => {
    try {
      const response = await getPreviewPromotions(promotionId);
      if (response.status === 'ok' && response.promotions.length === 0) {
        dispatch(showWarning({ message: '단지에 속한 배너가 없습니다' }));
      }
      return response;
    } catch (err) {
      dispatch(showError({ message: '배너 조회 중 오류가 발생했습니다' }));
      return Promise.reject(err);
    }
  },
);

export type { GetOption };
export { getAsync, getPreviewAsync };
