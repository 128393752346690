import { useState } from 'react';
import { useAppSelector } from 'app/hooks';

import { styled, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { selectAnnouncements } from 'features/announcements';
import NextButton from './icons/NextButton';

const replaceContents = [
  {
    // 화재시 대피방법
    videoUrl:
      'https://assets.focusmediakorea.com/vertical-full/notification/C3BCC/d5a4513c-546b-4d08-a1af-6fc480b46a48/0bd4582b-945a-4fd0-ada5-36b376eb005c.mp4',
    thumbnailUrl: '/fire_evacuation.jpg',
  },
  {
    // 소화기 안전점검
    videoUrl:
      'https://assets.focusmediakorea.com/vertical-full/notification/C3BCC/ad7d2128-57a8-4563-a870-072016880421/00c4f108-a3eb-4643-9ad3-843cc045020d.mp4',
    thumbnailUrl: '/fire_extinguisher.jpg',
  },
  {
    // 에티켓
    videoUrl:
      'https://assets.focusmediakorea.com/vertical-full/notification/C3BCC/dc09891f-a335-4d10-a0e5-6bafd4503aaa/05d99aba-db94-4718-b5d0-1fef3fc22a96.mp4',

    thumbnailUrl: '/etiquette.jpg',
  },
  {
    // 펫티켓
    videoUrl:
      'https://assets.focusmediakorea.com/vertical-full/notification/C3BCC/e3d6cd09-16a3-4520-9115-f42ec7f72212/91766e23-f95c-4b89-b675-381a83f81c33.mp4',
    thumbnailUrl: '/pettiquette.jpg',
  },
];

const AnnouncementsSlider = styled(Slider)`
  .slick-list,
  .slick-track {
    box-sizing: border-box;
    height: 100%;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    width: 398px;
    padding: 0 5px;
    margin-top: 17px;
    max-height: 100%;
  }
  .slick-dots {
    position: unset;
    display: flex !important;
    justify-content: center;
    margin: 12px auto 0;
    max-width: 398px;
    width: 100%;
    list-style-type: none;
    overflow: hidden;
  }
  .slick-dots li {
    position: relative;
    height: 10px;
    flex: 1;
    margin: 0;
  }
  li.slick-active button {
    position: absolute;
    right: 0;
    width: 100vw;
  }
  .slick-dots button {
    display: block;
    width: 100%;
    height: 4px;
    padding: 0;
    background-color: #e8ebed;
    text-indent: -9999px;
    border: none;
  }
  .slick-dots li.slick-active button {
    background-color: #9ea4aa;
  }

  .slick-prev {
    left: 14px;
    z-index: 20;
  }
  .slick-next {
    right: 14px;
  }
  .slick-prev,
  .slick-next {
    width: 64px;
    height: 64px;
  }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0;
  }
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  @media (max-width: 768px) {
    .slick-slider {
      touch-action: pinch-zoom !important;
      max-height: 100%;
    }
    .slick-slide {
      width: 290px;
      padding: 0 5px;
    }
    .slick-dots {
      max-width: 290px;
      width: 100%;
    }
  }
`;

const ReplaceContentBox = styled('video')(({ theme }) => ({
  borderRadius: '4px',
  border: '1px solid #E8EBED',
  maxWidth: '398px',
  height: '100%',
  [theme.breakpoints.down('tablet')]: {
    width: '290px',
  },
}));

const AnnouncementBox = styled('img')(({ theme }) => ({
  borderRadius: '4px',
  border: '1px solid #E8EBED',
  maxWidth: '398px',
  [theme.breakpoints.down('tablet')]: {
    width: '290px',
  },
}));

const Pagination = styled(Typography)(({ theme }) => ({
  position: 'unset',
  display: 'flex',
  justifyContent: 'right',
  listStyleType: 'none',
  maxWidth: '398px',
  margin: '22px auto 0',
  color: '#454C53',
  fontSize: '0.875rem',
  fontWeight: 500,
  [theme.breakpoints.down('tablet')]: {
    width: '290px',
  },
}));

const TotalPage = styled(Typography)(() => ({
  color: '#9EA4AA',
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const NextArrowButton = (props: any) => {
  const { className, style, onClick, isMirrored, display } = props;
  return (
    <div className={className} style={{ ...style, display }} onClick={onClick}>
      <NextButton isMirrored={isMirrored} />
    </div>
  );
};

const Announcements = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const { announcement, announcementFromFomigo } = useAppSelector(selectAnnouncements);

  const setting = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    centerMode: true,
    speed: 300,
    useTransform: false,
    variableWidth: replaceContents.length > 1,
    centerPadding: '0px 0px',
    arrows: true,
    afterChange: (current: number) => {
      setPage(current + 1);
    },
    nextArrow: <NextArrowButton />,
    prevArrow: <NextArrowButton isMirrored={true} />,
  };

  return (
    <Grid sx={{ height: 'calc(100% - 44px)', marginTop: '16px', boxSizing: 'border-box' }}>
      <AnnouncementsSlider {...setting} sx={{ touchAction: 'pinch-zoom', boxSizing: 'border-box', height: '420px' }}>
        {renderSliderContents(
          announcement.length ? announcement : announcementFromFomigo.length ? announcementFromFomigo : replaceContents,
        )}
      </AnnouncementsSlider>
      <Pagination>
        {page}&nbsp;
        <TotalPage>/ {announcementFromFomigo.length || announcement.length || replaceContents.length}</TotalPage>
      </Pagination>
    </Grid>
  );
};

const isMp4 = (url: string) => url.split('.').pop() === 'mp4';

const renderSliderContents = (contents: any) => {
  return contents.map((content: any, idx: number) => {
    return isMp4(content.videoUrl) ? (
      <ReplaceContentBox key={`announcement-${idx}`} controls preload="none" poster={content?.thumbnailUrl || ''}>
        <source src={content.videoUrl} type="video/mp4" />
      </ReplaceContentBox>
    ) : (
      <AnnouncementBox key={`announcement-${idx}`} src={content.videoUrl} />
    );
  });
};

export { Announcements };
