import { useAppDispatch } from '../app/hooks';
import { resolveAsync } from '../features/initiator';
import { Button, styled, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { LogoIcon } from './icons';
import { Container } from './shared';

interface ErrorProps {
  cd?: string | null;
  sn?: string | null;
}

const RadiusButton = styled(Button)(() => ({
  marginTop: '50px',
  borderRadius: '100px',
  border: '1px solid #304FFE',
  background: '#304FFE',
}));

const EventError = ({ cd, sn }: ErrorProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const onClickRetry = () => {
    let payload = {};
    if (cd) {
      payload = { cd, qrCode: cd };
    } else {
      payload = { sn };
    }
    dispatch(resolveAsync(payload));
  };

  const onClickMoveHomepage = () => {
    const homepageUrl = process.env.REACT_APP_HOMEPAGE_SERVICE_API_URL || 'https://www.focusmediakorea.com';
    window.location.href = homepageUrl;
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
      <Container textAlign="center">
        <LogoIcon />
        <Typography variant="h5" mt="10px">
          QR 코드를 확인할 수 없습니다.
        </Typography>
        <Typography>잘못된 접근이거나, 네트워크 문제일 수 있습니다.</Typography>
        <RadiusButton variant="contained" onClick={onClickMoveHomepage} sx={{ marginRight: '16px' }}>
          포커스미디어코리아 홈페이지로 이동
        </RadiusButton>
        <RadiusButton variant="contained" onClick={onClickRetry}>
          재시도
        </RadiusButton>
      </Container>
    </Grid>
  );
};

export { EventError };
