import { Alert, Snackbar } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dismissNotification, selectNotification } from 'features/notification/notification-slice';

const Notification = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotification);
  const handleSnackbarClose = () => {
    dispatch(dismissNotification());
  };

  return (
    <Snackbar
      sx={{ marginTop: '56px' }}
      open={!notification.dismissed}
      autoHideDuration={notification.duration}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        variant="filled"
        severity={notification.severity}
        onClose={handleSnackbarClose}
        sx={{ whiteSpace: 'pre-wrap' }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export { Notification };
