import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResolveQRCodeResult } from './initiator-slice';
import {
  ResolveParams,
  GetBuildingTypeCodeParams,
  getBuildingTypeCode,
  resolve,
  getDeviceInfoFromHades,
} from './initiator-api';
import { showError } from '../notification/notification-slice';

const resolveAsync = createAsyncThunk(
  'initiator/get',
  async (payload: ResolveParams, { dispatch }): Promise<ResolveQRCodeResult> => {
    try {
      return await resolve(payload);
    } catch (err) {
      dispatch(showError({ message: 'QR 코드를 확인할 수 없습니다' }));
      return Promise.reject(err);
    }
  },
);

const getBuildingTypeAsync = createAsyncThunk(
  'building-type/get',
  async (payload: GetBuildingTypeCodeParams): Promise<string> => {
    try {
      return await getBuildingTypeCode(payload);
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getDeviceInfoAsync = createAsyncThunk('device-info/get', async (payload: string): Promise<string> => {
  try {
    return await getDeviceInfoFromHades(payload || '');
  } catch (err) {
    return Promise.reject(err);
  }
});

export { getBuildingTypeAsync, resolveAsync, getDeviceInfoAsync };
