const KakaoIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#F7E74D" />
      <path
        d="M24 12C17.3724 12 12 16.458 12 21.9574C12 25.5371 14.2765 28.6737 17.6926 30.429C17.4414 31.4129 16.7837 33.9923 16.6519 34.5444C16.4897 35.2295 16.8913 35.2204 17.1542 35.0357C17.3606 34.8915 20.4428 32.6925 21.7726 31.7431C22.4943 31.8554 23.2385 31.9147 24 31.9147C30.6276 31.9147 36 27.4561 36 21.9574C36 16.458 30.6276 12 24 12Z"
        fill="#391B1B"
      />
    </svg>
  );
};

export { KakaoIcon };
