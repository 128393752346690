import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import notificationReducer from 'features/notification/notification-slice';
import promotionReducer from 'features/promotions/promotions-slice';
import initiatorReducer from 'features/initiator/initiator-slice';
import announcementReducer from 'features/announcements/announcements-slice';

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    promotions: promotionReducer,
    initiator: initiatorReducer,
    announcements: announcementReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
