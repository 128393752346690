import { Box, LinearProgress, styled, Unstable_Grid2 as Grid } from '@mui/material';
import { InitiatorSkeleton } from './skeletons';

const Container = styled(Grid)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '280px',
  },
  [theme.breakpoints.between('mobile', 'tablet')]: { width: '320px' },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '664px',
  },
}));

const EventPending = (): JSX.Element => {
  return (
    <Box>
      <LinearProgress />
      <Grid container justifyContent="center">
        <Container>
          <Box>
            <InitiatorSkeleton style={{ height: '58px' }} />
          </Box>
        </Container>
      </Grid>
      <Grid container justifyContent="center">
        <Container>
          <div>
            <InitiatorSkeleton style={{ height: '320px' }} />
          </div>
        </Container>
      </Grid>
      <Grid container justifyContent="center">
        <Container>
          <div>
            <InitiatorSkeleton style={{ height: '282px' }} />
          </div>
        </Container>
      </Grid>
    </Box>
  );
};

export { EventPending };
