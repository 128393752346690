const FooterLogoIcon = () => {
  return (
    <svg width="110" height="26" viewBox="0 0 110 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.805 15.7802H11.5445V17.9596H6V20.7325H20.4187V17.9596H14.805V15.7802Z" fill="#0F02B9" />
      <path d="M19.7743 6.33072H6.66284V9.12062H19.7743V6.33072Z" fill="#0F02B9" />
      <path d="M32.4843 11.4908H29.9742V14.4376H32.4843V20.5756H35.4664V6.33072H32.4843V11.4908Z" fill="#0F02B9" />
      <path
        d="M22.355 9.08529H26.8183V10.6202C26.8183 10.9509 26.8013 11.2646 26.766 11.5798H22.355V14.2482H25.9646C25.0926 16.0092 23.5761 17.4918 21.5353 18.5023L23.61 20.594C27.5334 18.4669 29.8004 14.6835 29.8004 10.3248V6.33214H22.355V9.08529Z"
        fill="#0F02B9"
      />
      <path
        d="M45.771 8.96374C46.1201 8.16097 46.3632 7.34266 46.5031 6.4876L43.3118 6C42.7366 9.2775 40.5572 11.789 37.226 12.9917L39.0393 15.3463C40.9925 14.6312 42.8581 13.2362 44.2347 11.4752C45.8219 13.0624 47.9136 14.3005 50.3021 14.8757L51.9755 12.4349C49.5191 11.8399 47.3228 10.6371 45.771 8.96374Z"
        fill="#0F02B9"
      />
      <path d="M51.8045 17.9596H37.3843V20.7325H51.8045V17.9596Z" fill="#0F02B9" />
      <path
        d="M53.5655 20.5756H61.7077V6.33072H53.5655V20.5756ZM56.3893 9.08529H58.9008V17.838H56.3893V9.08529Z"
        fill="#0F02B9"
      />
      <path d="M66.8522 6.33072H63.8701V20.5756H66.8522V6.33072Z" fill="#0F02B9" />
      <path d="M72.2383 9.08529H77.3998V6.33072H69.2576V20.5756H78.1149V17.838H72.2383V9.08529Z" fill="#0F02B9" />
      <path d="M82.5443 6.33072H79.5622V20.5756H82.5443V6.33072Z" fill="#0F02B9" />
      <path
        d="M95.2543 6.33072V11.4046H93.1443V10.5848C93.1443 7.81189 91.5048 6.17384 88.9778 6.17384C86.4324 6.17384 84.7929 7.8133 84.7929 10.5848V16.3215C84.7929 19.0945 86.4324 20.7325 88.9778 20.7325C91.5062 20.7325 93.1443 19.0931 93.1443 16.3215V14.3683H95.2543V20.5756H98.2365V6.33072H95.2543ZM90.3374 16.3215C90.3374 17.4027 89.7975 17.9949 88.9778 17.9949C88.158 17.9949 87.6181 17.4027 87.6181 16.3215V10.5848C87.6181 9.5206 88.158 8.92841 88.9778 8.92841C89.7975 8.92841 90.3374 9.5206 90.3374 10.5848V16.3215Z"
        fill="#0F02B9"
      />
      <path
        d="M19.9043 15.188V12.4674H17.7603V9.76511H14.6566V12.4674H13.1825H11.7098V9.76511H8.60618V12.4674H6.46075V15.188H13.1825H19.9043Z"
        fill="#0F02B9"
      />
      <path
        d="M101.856 20.7325C103.04 20.7325 104 19.7726 104 18.5885C104 17.4044 103.04 16.4445 101.856 16.4445C100.672 16.4445 99.712 17.4044 99.712 18.5885C99.712 19.7726 100.672 20.7325 101.856 20.7325Z"
        fill="#14D214"
      />
    </svg>
  );
};

export { FooterLogoIcon };
