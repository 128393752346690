import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { PromotionListViewItem } from 'models';
import { getAsync, getPreviewAsync } from './promotions-thunks';
import { RootState } from 'app/store';

type LoadingStatus = 'loaded' | 'loading' | 'init';

interface PromotionListState {
  total: number;
  items: PromotionListViewItem[];
  loading: LoadingStatus;
}

interface GetPromotionResult {
  status: 'ok' | 'error';
  promotions: PromotionListViewItem[];
}

const initialState: PromotionListState = {
  total: 0,
  items: [],
  loading: 'init',
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PromotionListState>) => {
    builder
      .addCase(getAsync.pending, (state: Draft<PromotionListState>) => {
        state.loading = 'loading';
      })
      .addCase(getAsync.rejected, (state: Draft<PromotionListState>) => {
        state.loading = 'init';
      })
      .addCase(getPreviewAsync.pending, (state: Draft<PromotionListState>) => {
        state.loading = 'loading';
      })
      .addCase(getPreviewAsync.rejected, (state: Draft<PromotionListState>) => {
        state.loading = 'init';
      })
      .addCase(getAsync.fulfilled, (state: Draft<PromotionListState>, action: PayloadAction<GetPromotionResult>) => {
        if (action.payload && action.payload.status === 'ok') {
          state.items = action.payload.promotions;
          state.total = state.items.length;
          state.loading = 'loaded';
        }
      })
      .addCase(
        getPreviewAsync.fulfilled,
        (state: Draft<PromotionListState>, action: PayloadAction<GetPromotionResult>) => {
          if (action.payload && action.payload.status === 'ok') {
            state.items = action.payload.promotions;
            state.total = state.items.length;
            state.loading = 'loaded';
          }
        },
      );
  },
});

// selectors
export const selectPromotions = (state: RootState) => state.promotions;

export type { GetPromotionResult };
export default promotionsSlice.reducer;
