import { useState } from 'react';

import { useAppDispatch } from '../app/hooks';
import { showError } from 'features/notification/notification-slice';
import { Box, Modal, styled, Typography } from '@mui/material';
import { Close, ChevronRight, NotificationsNone } from '@mui/icons-material';
import { Announcements } from './Announcements';
import { useAppSelector } from 'app/hooks';
import { selectInitiator } from 'features/initiator';
import { selectAnnouncements } from 'features/announcements';

const NotificationBar = styled('div')(({ theme }) => ({
  margin: '0 20px',
  fontSize: '1rem',
  fontWeight: '600',
  padding: '16px',
  justifyContent: 'space-between',
  color: '#FFF',
  display: 'flex',
  alignItems: 'center',
  background: '#1B1D1F',
  boxShadow: '0px 4px 12px rgba(27, 29, 31, 0.12)',
  borderRadius: '4px',
  [theme.breakpoints.down('mobile')]: {
    fontSize: '0.875rem',
    margin: '0 16px',
  },
  [theme.breakpoints.between('mobile', 'tablet')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '632px',
    margin: '0 auto',
  },
}));

const AnnouncementsModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('tablet')]: {
    height: 'calc(var(--vh) * 100)',
  },
}));

const AnnouncementsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '488px',
  height: '722px',
  overflow: 'hidden',
  background: '#FFF',
  borderRadius: '20px',
  [theme.breakpoints.down('tablet')]: {
    width: '100vw',
    maxWidth: '767px',
    maxHeight: '560px',
    borderRadius: '20px 20px 0 0',
    position: 'absolute',
    bottom: '0',
  },
}));

const NotificationBox = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { item } = useAppSelector(selectInitiator);
  const { announcementFromFomigo } = useAppSelector(selectAnnouncements);

  const OpenAnnouncementBox = () => {
    if (item?.buildingProductCode === 'S' && announcementFromFomigo?.length === 0) {
      dispatch(showError({ message: '등록된 공지사항이 없습니다.' }));
    } else {
      setOpen(true);
    }
  };

  const CloseAnnouncementBox = () => {
    setOpen(false);
  };

  return (
    <>
      <NotificationBar onClick={OpenAnnouncementBox}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NotificationsNone
            sx={{
              marginRight: '8px',
              width: '28px',
            }}
          />
          <Typography sx={{ lineHeight: 'initial' }}>우리 단지 공지사항 확인하기</Typography>
        </Box>
        <ChevronRight
          sx={{
            color: '#72787F',
          }}
        />
      </NotificationBar>
      <AnnouncementsModal open={open} onClose={CloseAnnouncementBox}>
        <AnnouncementsBox>
          <Close
            onClick={CloseAnnouncementBox}
            sx={{
              alignSelf: 'end',
              color: '#9EA4AA',
              padding: '20px 20px 0 0',
            }}
          />
          <Announcements />
        </AnnouncementsBox>
      </AnnouncementsModal>
    </>
  );
};

export { NotificationBox };
