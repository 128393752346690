import RestApiClient from 'lib/rest-api-client';
import { GetPromotionResult } from './promotions-slice';
import { GetOption } from './promotions-thunks';
import { PromotionListViewItem } from 'models';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_HESTIA_SERVICE_API_URL || '',
  timeout: 10 * 1000,
});

const getPromotions = async (params: GetOption): Promise<GetPromotionResult> => {
  try {
    const response = await restApiClient.get('/promotions/live', { params });
    return {
      status: 'ok',
      promotions: (response.data || []) as PromotionListViewItem[],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPreviewPromotions = async (promotionId: string): Promise<GetPromotionResult> => {
  try {
    const response = await restApiClient.get('/promotions/preview', { params: { promotionId } });
    return {
      status: 'ok',
      promotions: (response.data || []) as PromotionListViewItem[],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

export { getPromotions, getPreviewPromotions };
