import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { EventPage } from 'pages/event/EventPage';

const AppRouter = (): JSX.Element => {
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  // 로컬환경 GA 트래킹 제외를 위한 설정
  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      ReactGA.initialize('G-82N18JN96X');
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname + location.search });
      ReactGA.send('pageview');
    }
  }, [initialized, location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<EventPage />} />
        <Route path="/event/" element={<EventPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;
