import { createTheme } from '@mui/material';

declare module '@mui/system' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
    xsMobileContainer: true;
    mobileContainer: true;
    tabletContainer: true;
    desktopContainer: true;
  }
}
export const theme = createTheme({
  typography: {
    fontFamily: 'pretendard',
    h2: {
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    subtitle1: {
      color: '#454C53',
      marginBottom: '8px',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
    },
  },
  breakpoints: {
    values: {
      mobile: 360,
      tablet: 768,
      desktop: 1920,
      xsMobileContainer: 280,
      mobileContainer: 320,
      tabletContainer: 664,
      desktopContainer: 664,
    },
  },
});
