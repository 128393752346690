import RestApiClient from 'lib/rest-api-client';
import { ResolveQRCodeResult } from './initiator-slice';
import { InitiatorResponse } from 'models';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_QR_SERVICE_API_URL || '',
  timeout: 10 * 1000,
});

export interface ResolveParams {
  cd?: string;
  sn?: string;
}

export interface GetBuildingTypeCodeParams {
  qrCode?: string;
  sn?: string;
}

/**
 * QR 코드를 이용해 FMS DB에서 빌딩 정보 조회 API 호출
 * @param cd QR 코드
 */
const resolve = async (params: ResolveParams): Promise<ResolveQRCodeResult> => {
  try {
    const response = await restApiClient.get('/event/', { params });
    return {
      status: 'ok',
      initiator: response.data as InitiatorResponse,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getBuildingTypeCode = async (params: GetBuildingTypeCodeParams, version = 'live'): Promise<string> => {
  try {
    const response = await restApiClient.get('/api/building_type.php', { params: { params, version } });
    return response.data.result;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getDeviceInfoFromHades = async (serialNumber: string): Promise<string> => {
  try {
    const hadesResponse = await restApiClient.get(`/devices/${serialNumber}`, {
      baseURL: process.env.REACT_APP_HADES_SERVICE_API_URL,
    });

    return hadesResponse.data.deviceType || '';
  } catch (err) {
    return Promise.reject(err);
  }
};

export { getBuildingTypeCode, resolve, getDeviceInfoFromHades };
