import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetAnnouncementResult } from './announcements-slice';
import { getAnnouncements, getAnnouncementsFromHades } from './announcements-api';

const getAnnouncementsAsync = createAsyncThunk(
  'announcements/get',
  async (buildingCode: string): Promise<GetAnnouncementResult> => {
    try {
      return await getAnnouncements(buildingCode);
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getAnnouncementsFromHadesAsync = createAsyncThunk(
  'announcements/get/fomigo',
  async (serialNumber: string): Promise<GetAnnouncementResult> => {
    try {
      return await getAnnouncementsFromHades(serialNumber);
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export { getAnnouncementsAsync, getAnnouncementsFromHadesAsync };
