import { Box, Card, CardContent, styled, Typography } from '@mui/material';

type Props = {
  children: JSX.Element;
  subTitle: string | JSX.Element;
  icon: JSX.Element;
  onClick: () => void;
};

const ButtonCard = styled(Card)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E8EBED',
  borderRadius: 0,
}));

const Content = styled(CardContent)(() => ({
  padding: '17px 0 20px 20px !important',
  flex: '1 0 auto',
}));

const ContentWrap = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const IconBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '20px',
}));

const EventButton = (props: Props) => {
  return (
    <ButtonCard onClick={props.onClick}>
      <ContentWrap>
        <Content>
          <Typography variant="subtitle1">{props.subTitle}</Typography>
          <Typography variant="h2" color="#1B1D1F">
            {props.children}
          </Typography>
        </Content>
      </ContentWrap>
      <IconBox>{props.icon}</IconBox>
    </ButtonCard>
  );
};

export { EventButton };
