import { styled, Unstable_Grid2 as Grid } from '@mui/material';

const Container = styled(Grid)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('mobile')]: { maxWidth: '280px' },
  [theme.breakpoints.between('mobile', 'tablet')]: { maxWidth: '320px' },
  [theme.breakpoints.up('tablet')]: { maxWidth: '664px' },
}));

export { Container };
