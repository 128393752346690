import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { AnnouncementFromFomigo, AnnouncementFromCms } from 'models';
import { getAnnouncementsAsync, getAnnouncementsFromHadesAsync } from './announcements-thunks';
import { RootState } from 'app/store';

type LoadingStatus = 'init' | 'loading' | 'loaded' | 'error';

interface AnnouncementListState {
  announcement: AnnouncementFromCms[];
  announcementFromFomigo: AnnouncementFromFomigo[];
  loading: LoadingStatus;
}

interface GetAnnouncementResult {
  status: 'ok' | 'error';
  announcement?: AnnouncementFromCms[];
  announcementFromFomigo?: AnnouncementFromFomigo[];
}

const initialState: AnnouncementListState = {
  announcement: [],
  announcementFromFomigo: [],
  loading: 'loaded',
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AnnouncementListState>) => {
    builder
      .addCase(
        getAnnouncementsAsync.fulfilled,
        (state: Draft<AnnouncementListState>, action: PayloadAction<GetAnnouncementResult>) => {
          if (action.payload && action.payload.status === 'ok') {
            state.announcement = action.payload?.announcement as AnnouncementFromCms[];
            state.loading = 'loaded';
          }
        },
      )
      .addCase(
        getAnnouncementsFromHadesAsync.fulfilled,
        (state: Draft<AnnouncementListState>, action: PayloadAction<GetAnnouncementResult>) => {
          if (action.payload && action.payload.status === 'ok') {
            state.announcementFromFomigo = action.payload?.announcementFromFomigo as AnnouncementFromFomigo[];
            state.loading = 'loaded';
          }
        },
      )
      .addCase(getAnnouncementsAsync.rejected, (state: Draft<AnnouncementListState>) => {
        state.loading = 'error';
      })
      .addCase(getAnnouncementsFromHadesAsync.rejected, (state: Draft<AnnouncementListState>) => {
        state.loading = 'error';
      });
  },
});

// selectors
export const selectAnnouncements = (state: RootState) => state.announcements;

export type { GetAnnouncementResult };
export default announcementsSlice.reducer;
