import { Skeleton } from '@mui/material';

interface InitiatorSkeletonProps {
  style: object;
}

const InitiatorSkeleton = ({ style }: InitiatorSkeletonProps) => {
  return (
    <div style={{ padding: '10px' }}>
      <Skeleton variant="rectangular" width="100%">
        <div style={style} />
      </Skeleton>
    </div>
  );
};

export { InitiatorSkeleton };
