export default function NextButton({ isMirrored }: { isMirrored?: boolean }) {
  const transformStyle = isMirrored ? { transform: 'scaleX(-1)' } : {};

  return (
    <svg
      style={{ ...transformStyle }}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_134_1866)">
        <rect x="12" y="12" width="40" height="40" rx="20" fill="white" />
        <mask
          id="mask0_134_1866"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="20"
          y="20"
          width="24"
          height="24"
        >
          <rect x="20" y="20" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_134_1866)">
          <path
            d="M29.2245 37.475C29.0745 37.325 28.9995 37.15 28.9995 36.95C28.9995 36.75 29.0745 36.575 29.2245 36.425L33.2995 32.35L29.2245 28.275C29.0745 28.125 28.9995 27.95 28.9995 27.75C28.9995 27.55 29.0745 27.375 29.2245 27.225C29.3745 27.075 29.5495 27 29.7495 27C29.9495 27 30.1245 27.075 30.2745 27.225L34.7745 31.725C34.8578 31.8083 34.9205 31.904 34.9625 32.012C35.0038 32.1207 35.0245 32.2333 35.0245 32.35C35.0245 32.4667 35.0038 32.579 34.9625 32.687C34.9205 32.7957 34.8578 32.8917 34.7745 32.975L30.2745 37.475C30.1245 37.625 29.9495 37.7 29.7495 37.7C29.5495 37.7 29.3745 37.625 29.2245 37.475Z"
            fill="#9EA4AA"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_134_1866"
          x="0"
          y="0"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_1866" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_1866" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
