import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getBuildingTypeAsync, resolveAsync, getDeviceInfoAsync } from './initiator-thunks';
import { InitiatorResponse } from 'models';

interface InitiatorState {
  item: InitiatorResponse | undefined;
  buildingType: string;
  qrCodeResolveResult: 'none' | 'pending' | 'success' | 'failed';
  deviceType: string;
}

interface ResolveQRCodeResult {
  status: 'ok' | 'error';
  initiator: InitiatorResponse | undefined;
}

const initialState: InitiatorState = {
  item: undefined,
  buildingType: '',
  qrCodeResolveResult: 'none',
  deviceType: '',
};

const initiatorSlice = createSlice({
  name: 'initiator',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<InitiatorState>) => {
    builder
      .addCase(resolveAsync.pending, (state: Draft<InitiatorState>) => {
        state.qrCodeResolveResult = 'pending';
      })
      .addCase(resolveAsync.rejected, (state: Draft<InitiatorState>) => {
        state.qrCodeResolveResult = 'failed';
      })
      .addCase(resolveAsync.fulfilled, (state: Draft<InitiatorState>, action: PayloadAction<ResolveQRCodeResult>) => {
        if (action.payload && action.payload.status === 'ok') {
          state.item = action.payload.initiator;
          state.qrCodeResolveResult = 'success';
        }
      })
      .addCase(getBuildingTypeAsync.fulfilled, (state: Draft<InitiatorState>, action: PayloadAction<string>) => {
        state.buildingType = action.payload;
      })
      .addCase(getDeviceInfoAsync.fulfilled, (state: Draft<InitiatorState>, action: PayloadAction<string>) => {
        state.deviceType = action.payload;
      })
      .addCase(getDeviceInfoAsync.rejected, (state: Draft<InitiatorState>) => {
        state.deviceType = '';
      });
  },
});

export const selectInitiator = (state: RootState) => state.initiator;

export type { ResolveQRCodeResult };
export default initiatorSlice.reducer;
